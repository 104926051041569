<template>
  <div class="v4-news-container">
    <div class="news__inner">
      <div class="news-main-container">
        <template v-if="$bowserMobile">
          <div class="main-body__item sticky-body">
            <div class="item__inner">
              <template v-if="commonList.length > 0">
                <router-link
                  tag="div"
                  :to="{
                    path: `/news/${list.id}`
                  }"
                  class="news__item scrollObj"
                  v-for="(list, index) in commonList"
                  :key="index"
                >
                  <div class="news__cover">
                    <img :data-src="list.cover.outter" alt="" ref="lazyload" />
                  </div>
                  <div class="news__info">
                    <div class="info__title">
                      <p>{{ list.title.en }}</p>
                      <p>{{ list.title.cn }}</p>
                    </div>
                    <div class="info__publish">{{ list.publish_at }}</div>
                    <div class="info__summary">{{ list.summary }}</div>
                  </div>
                </router-link>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="main-body__item sticky-body scrollEffect"
            data-extend="sticky"
            ref="scrollEffect"
          >
            <div class="item__inner" v-if="fixedList">
              <router-link
                tag="div"
                :to="{
                  path: `/news/${list.id}`
                }"
                class="news__item scrollObj"
                v-for="(list, index) in fixedList"
                :key="index"
                :class="{
                  hoverClass: !$bowserMobile
                }"
              >
                <div class="news__cover">
                  <img :data-src="list.cover.outter" alt="" ref="lazyload" />
                </div>
                <div class="news__info">
                  <div class="info__title">
                    <p>{{ list.title.en }}</p>
                    <p>{{ list.title.cn }}</p>
                  </div>
                  <div class="info__publish">{{ list.publish_at }}</div>
                  <div class="info__summary">{{ list.summary }}</div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="main-body__item main-body sidebar-body">
            <ul class="item__inner media-news-wrapper">
              <router-link
                tag="li"
                :to="{
                  path: `/news/${list.id}`
                }"
                class="news__item scrollObj"
                v-for="(list, index) in commonList"
                :key="index"
                :class="{
                  hoverClass: !$bowserMobile
                }"
              >
                <div class="news__cover ">
                  <img :data-src="list.cover.outter" alt="" ref="lazyload" />
                </div>
                <div class="news__info">
                  <div class="info__title">
                    <p>{{ list.title.en }}</p>
                    <p>{{ list.title.cn }}</p>
                  </div>
                  <div class="info__publish">{{ list.publish_at }}</div>
                  <div class="info__summary">{{ list.summary }}</div>
                </div>
              </router-link>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import { demo2OnScroll } from '@/assets/utils/common';
import { mapState } from 'vuex';
import pageFooter from '../common/page-footer.vue';

export default {
  name: 'v4-page-news-list',
  components: {
    pageFooter,
  },
  data() {
    return {
      lists: [],
      fixedList: null,
      commonList: [],
      page: 1,
      totalPage: 1,
      requesting: false,
    };
  },
  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  computed: {
    ...mapState(['$bowserMobile']),
    disabled() {
      return this.requesting || this.page > this.totalPage;
    },
  },
  mounted() {
    const that = this;
    that.refresh();
  },
  methods: {
    init() {
      this.$store.commit('setBackgroundColor', '#fff');
      document.body.style.cssText = 'background-color:inherit;';
      document.body.classList.remove('white-logo');
      this.page = 1;
      this.totalPage = 1;
      if (!this.$bowserMobile) {
        this.getFixedNews();
        this.getNews(1);
      } else {
        this.getCommonNews();
      }
    },
    imgLoad(imgs) {
      const ps = [];
      imgs.forEach((item) => {
        const { src } = item.dataset;
        const p = new Promise((resolve) => {
          const img = new Image();
          img.onload = function () {
            item.setAttribute('src', src);
            resolve();
          };
          img.src = src;
        });
        ps.push(p);
      });
      return Promise.all(ps);
    },
    getFixedNews() {
      // 置顶
      this.getNews(2);
    },
    getMoreNews() {
      // 不置顶
      this.getNews(1);
    },
    getCommonNews() {
      // 全部
      this.getNews(0);
    },
    getNews(isFixed) {
      const that = this;
      that.$fly
        .get('/api/media/news', {
          is_fixed: isFixed,
          page: that.page,
        })
        .then((res) => {
          const { data } = res;
          if (isFixed === 2) {
            that.fixedList = data.list;
          } else {
            that.page += 1;
            that.totalPage = data._meta.pageCount;
            that.commonList.push(...data.list);
          }
          that.refresh();
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    refresh() {
      const that = this;
      that.$nextTick(() => {
        demo2OnScroll();
        const { scrollEffect, lazyload } = that.$refs;
        if (lazyload) {
          that.imgLoad(lazyload).then(() => {
            if (scrollEffect) {
              const bcr = scrollEffect.getBoundingClientRect();
              scrollEffect.style.cssText = `top:${window.innerHeight - bcr.height}px;`;
            }
          });
        } else if (scrollEffect) {
          const bcr = scrollEffect.getBoundingClientRect();
          scrollEffect.style.cssText = `top:${window.innerHeight - bcr.height}px;`;
        }
      });
    },
  },
};
</script>

<style></style>
