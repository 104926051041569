var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v4-news-container"},[_c('div',{staticClass:"news__inner"},[_c('div',{staticClass:"news-main-container"},[(_vm.$bowserMobile)?[_c('div',{staticClass:"main-body__item sticky-body"},[_c('div',{staticClass:"item__inner"},[(_vm.commonList.length > 0)?_vm._l((_vm.commonList),function(list,index){return _c('router-link',{key:index,staticClass:"news__item scrollObj",attrs:{"tag":"div","to":{
                  path: ("/news/" + (list.id))
                }}},[_c('div',{staticClass:"news__cover"},[_c('img',{ref:"lazyload",refInFor:true,attrs:{"data-src":list.cover.outter,"alt":""}})]),_c('div',{staticClass:"news__info"},[_c('div',{staticClass:"info__title"},[_c('p',[_vm._v(_vm._s(list.title.en))]),_c('p',[_vm._v(_vm._s(list.title.cn))])]),_c('div',{staticClass:"info__publish"},[_vm._v(_vm._s(list.publish_at))]),_c('div',{staticClass:"info__summary"},[_vm._v(_vm._s(list.summary))])])])}):_vm._e()],2)])]:[_c('div',{ref:"scrollEffect",staticClass:"main-body__item sticky-body scrollEffect",attrs:{"data-extend":"sticky"}},[(_vm.fixedList)?_c('div',{staticClass:"item__inner"},_vm._l((_vm.fixedList),function(list,index){return _c('router-link',{key:index,staticClass:"news__item scrollObj",class:{
                hoverClass: !_vm.$bowserMobile
              },attrs:{"tag":"div","to":{
                path: ("/news/" + (list.id))
              }}},[_c('div',{staticClass:"news__cover"},[_c('img',{ref:"lazyload",refInFor:true,attrs:{"data-src":list.cover.outter,"alt":""}})]),_c('div',{staticClass:"news__info"},[_c('div',{staticClass:"info__title"},[_c('p',[_vm._v(_vm._s(list.title.en))]),_c('p',[_vm._v(_vm._s(list.title.cn))])]),_c('div',{staticClass:"info__publish"},[_vm._v(_vm._s(list.publish_at))]),_c('div',{staticClass:"info__summary"},[_vm._v(_vm._s(list.summary))])])])}),1):_vm._e()]),_c('div',{staticClass:"main-body__item main-body sidebar-body"},[_c('ul',{staticClass:"item__inner media-news-wrapper"},_vm._l((_vm.commonList),function(list,index){return _c('router-link',{key:index,staticClass:"news__item scrollObj",class:{
                hoverClass: !_vm.$bowserMobile
              },attrs:{"tag":"li","to":{
                path: ("/news/" + (list.id))
              }}},[_c('div',{staticClass:"news__cover "},[_c('img',{ref:"lazyload",refInFor:true,attrs:{"data-src":list.cover.outter,"alt":""}})]),_c('div',{staticClass:"news__info"},[_c('div',{staticClass:"info__title"},[_c('p',[_vm._v(_vm._s(list.title.en))]),_c('p',[_vm._v(_vm._s(list.title.cn))])]),_c('div',{staticClass:"info__publish"},[_vm._v(_vm._s(list.publish_at))]),_c('div',{staticClass:"info__summary"},[_vm._v(_vm._s(list.summary))])])])}),1)])]],2)]),_c('page-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }